import React, { Component } from "react";
import styled from 'styled-components';
import { Paragraph } from "../../utils/styles.util";
import { Row,Col } from "react-bootstrap";
import { MDBDataTableV5 } from 'mdbreact';
import { getAllPortfolio,deletePortofolio } from "../../middleware/portofolio"
import { connect } from "react-redux";
import * as Icon from 'react-feather';
import { showConfirmAlert,showAlert } from '../../utils/function.util';
import Loader from "../shared/loader.component";
import { POPUPTYPE } from "../../utils/constant.util";
import { Link } from 'react-router-dom'


const Container = styled.div`
  width: 100vw;
  display:flex;
  align-items: center;
  justify-content: center;
  background: url('https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80');
  background-size:cover;
`


const SecondContainer = styled.div`
  min-height:100vh;
  min-width:100vw;
  display:flex;
  justify-content:center;
  align-items:center;
`

const Card = styled.div`   
    max-width: 100%;
    height:100%;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    margin: 4rem;
`


class PortofolioViewAll extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            portofolio:null
        }
    }

    async componentDidMount(){
        await this.props.getAllPortfolio()
        let data = this.props.data
        data!== undefined && this.setState({
            portofolio: this.generatePortofolio(data)
        })
    }
    
    deletePortofolio(id,name){
      showConfirmAlert(`Are you sure want to delete ${name} ?`).then((res) => {
          if(res===true){
            this.setState({isLoading: true})
            this.props.deletePortofolio(id)
            .then((res) => {
                  this.setState({isLoading: false})
                  if(res.data.status === 1){
                    this.componentDidMount()
                    showAlert(res.data.message, POPUPTYPE.success)
                  } else {
                    showAlert(res.data.message, POPUPTYPE.error)
                  }
            }).catch((err) => {
              this.setState({isLoading: false})
              showAlert(err.message, POPUPTYPE.error)
            })
          }
      }).catch((err) => {
        showAlert(err.message, POPUPTYPE.error)
      })
    }

    generatePortofolio(data){
      var jsonArr = [];
      
      for(let i = 0 ; i < data.length;i++){
        for(let j=0;j< data[i].Portofolios.length;j++){
          jsonArr.push({
            name: data[i].Portofolios[j].title,
            category: data[i].category_portofolio_name,
            action:
            <>
              <Link
                to={{
                  pathname: "/AddPortofolio",
                  state: {
                    idpost: data[i].Portofolios[j].id
                },
                }}>
                  <Icon.Edit/>
              </Link>
              <Icon.Delete onClick={() => this.deletePortofolio(data[i].Portofolios[j].id,data[i].Portofolios[j].title)}/>
            </>
        });
        }
      }

      const dataTable = {
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'Name',
            },
          },
          {
            label: 'Category',
            field: 'category',
            width: 270,
          },
          {
            label: 'Action',
            field: 'action'
          },
        ],
        rows:jsonArr,
      }

      return dataTable
    }

    render(){
      return (
        <Container>
        <Loader isShown={this.state.isLoading}/>
        <SecondContainer>
        <Card>
          <Paragraph bold medium alignCenter>View All Portofolio</Paragraph>
            <Row>
              <Col>
            {this.state.portofolio !== null && 
              <MDBDataTableV5
                hover
                entriesOptions={[5, 10, 15]}
                entries={5}
                pagesAmount={4}
                data={this.state.portofolio}
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
              />
            }
            </Col>
            </Row>
        </Card>
        </SecondContainer>
        </Container>
      );
    }
}


const mapStateToProps = (state) => {
  return {
      data: state.portfolioReducer
  }
}

export default connect(mapStateToProps, { getAllPortfolio,deletePortofolio })(PortofolioViewAll);