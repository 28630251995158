import React from "react";
import styled from "styled-components"
import { Paragraph } from "../../utils/styles.util"
import blocksImg from "../../assets/blocks.png"
import { COLORS } from "../../utils/constant.util";

const Image = styled.img` 
    width: 100%;
    height:100%;
    object-fit: cover;
`

const Parent = styled.div`
    position: relative !important;  
    text-align:center;
    width:100%;
    height: 90vh;
`

const TitleContainer = styled.div`
    position:absolute;
    top: 75%;
    left: 0%;
    background: ${COLORS.white};
    display: flex;
    align-items: center;
    padding: 1rem 5rem;
    text-align: center;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
`

const Hexagonal = styled.img`
    width: 100vw;
    right:0;
    position: absolute;
    z-index:-1;
    top:80%;
    height: 20%;
    object-fit: cover;
    object-position: 100% 100%;
`

export default function Banner(props){
    return(
        <Parent>    
            <Image src={props.image}/>
            <TitleContainer>
                <Paragraph big centered>{props.title}</Paragraph>
            </TitleContainer>
            <Hexagonal src={blocksImg}/>
        </Parent>
    )
}