import { GET_ALL_PRODUCT, GET_ALL_PRODUCT_BY_CATEGORY } from "../actions/types";
const initialState = []

function shoppingReducer(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_ALL_PRODUCT_BY_CATEGORY:
            return payload
        case GET_ALL_PRODUCT:
            return payload
        default:
            return state
    }
}

export default shoppingReducer