import { NAVBAR_CHOOSE_SECTION } from "../actions/types";
import { menuSections } from "../../utils/constant.util";

const initialState = menuSections.HOME

function navbarReducer(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case NAVBAR_CHOOSE_SECTION:
            return payload
        default:
            return state
    }
}

export default navbarReducer