import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import mainteam1 from '../../assets/mainteam1.jpg'
import mainteam2 from '../../assets/mainteam2.jpg'
import mainteam3 from '../../assets/mainteam3.jpg'
import mainteam4 from '../../assets/mainteam4.jpg'
import subteam1 from '../../assets//subteam1.jpg'
import subteam2 from '../../assets//subteam2.jpg'
import subteam3 from '../../assets//subteam3.jpg'
import subteam5 from '../../assets//subteam5.jpg'
import subteam6 from '../../assets//subteam6.jpg'
import { Paragraph,Span } from '../../utils/styles.util';

const ImgMainTeam = styled.img`
    width:300px;
    height:400px;
`

const ImgSubTeam = styled.img`
    width:200px;
    height:300px;
`

export default function OurTeam(){
    const mainTeamList = [
        [
            {
                photo: mainteam1,
                name: "Chrisnady",
                job_title:"Project Manager"
            },
            {
                photo: mainteam2,
                name: "Laura",
                job_title:"Architect"
            }
        ],
        [
            {
                photo: mainteam3,
                name: "Tiffany",
                job_title:"Interior Design"
            },
            {
                photo: mainteam4,
                name: "Olivia",
                job_title:"Interior Design"
            }
        ]
    ]
    const subTeamList = [[subteam1,subteam2,subteam3],[subteam5,subteam6]]

    return(
        <Container>
                {
                    mainTeamList.map((mainTeam,i) => (
                      <div key={i}>
                      <Row  className="row-our-team">
                          {
                              mainTeam.map((main,i)=>(
                                <Col key={i} className="col-our-team">
                                    <ImgMainTeam src={main.photo}/>  
                                    <div style={{"marginTop":"0.5rem"}}>
                                        <Span alignStart>{main.name}</Span>
                                        <Paragraph alignStart tiny>{main.job_title}</Paragraph>
                                    </div>
                                </Col>
                              ))
                          }
                      </Row>
                      <br/>
                      </div>
                    ))
                }
                <br/>
                {
                    subTeamList.map((subTeam) => (
                      <>
                      <Row className="row-our-team">
                          {
                              subTeam.map((sub)=>(
                                <Col className="col-our-team">
                                    <ImgSubTeam src={sub}/>
                                </Col>
                              ))
                          }
                      </Row>
                      <br/>
                      </>
                    ))
                }
            </Container>
    );
}