import React, { Component } from 'react';
import Navbar from '../shared/navbar.component';
import Footer from '../shared/footer.component';
import { getAllProductsByCategory } from "../../middleware/shopping"
import { connect } from "react-redux";
import Backdrop from '../shared/backdrop.component';
import { Row,Container } from 'react-bootstrap';
import ShoppingBox from './shoppingbox.component';
import { Span } from "../../utils/styles.util";
import { Helmet } from 'react-helmet';

class ShoppingList extends Component{
    constructor(props){
        super(props)
        this.state = {
            categoryImage:"",
            categoryName:"",
            product: null
        }
    }

    async componentDidMount(){
        await this.props.getAllProductsByCategory(this.props.match.params.id)
        let data = this.props.data
        
        data!== undefined && this.setState({
            categoryImage: data[0].ImageURL,
            categoryName : data[0].name,
            product: this.generateGridProductData(data[0].Products)
        })
    }

    generateGridProductData(data){
        if(data===undefined || data.length === 0){
            return <Span big bold>NO DATA</Span>
        }
        else{
            return <ShoppingBox content={data}/>
        }
    }

    render(){
        return(
            <>
             <Helmet>
                <title>Shopping List</title>
             </Helmet>
            <Navbar/>
            <Backdrop image={this.state.categoryImage !== null && this.state.categoryImage} title={this.state.categoryName !== null && this.state.categoryName}/>
                <div style={{"marginTop": "8rem", "marginBottom": "8rem"}}>
                    <Container>
                        <Row className="justify-content-md-center" style={{"marginTop": "3rem", "justifyContent": "center"}}>
                            {this.state.product !== null && this.state.product}
                        </Row>
                    </Container> 
                </div>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.shoppingReducer
    }
}
export default connect(mapStateToProps, { getAllProductsByCategory })(ShoppingList);