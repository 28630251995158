import React from "react"
import styled from "styled-components"
import { Paragraph, Centered } from "../../utils/styles.util"
import blocksImg from "../../assets/blocks.png"

const BackgroundImgHeaderTitle = styled.img` 
    width: 100%;
    height:80vh;
    left:0;
    object-fit: cover;
`

const DivChildHeaderTitle = styled.div`
   width: 100%;
   height:95vh;
`

const DivParentHeaderTitle = styled.div`
    position: relative !important;  
    text-align:center;
    width:100%;
`

const Hexagonal = styled.img`
    width: 100vw;
    right:0;
    position: absolute;
    z-index:-1;
    top:80%;
    height: 20%;
    object-fit: cover;
    object-position: 100% 100%;
    
`
export default function Backdrop(props){
    return(
        <DivParentHeaderTitle>    
            <DivChildHeaderTitle>   
                <BackgroundImgHeaderTitle src={props.image}/>
                <Centered>
                    <Paragraph white medium big>{props.title}</Paragraph>
                </Centered>
                <Hexagonal src={blocksImg}/>
            </DivChildHeaderTitle>
        </DivParentHeaderTitle>
    )
}