import portfolioService from "../services/portfolio.service"
import { GET_PORTOFOLIO_BY_ID } from "../redux/actions/types"

const getPortofolioById = (id) => async (dispatch) => {
    try {
        const res = await portfolioService.fetchById(id)
        dispatch({
            type: GET_PORTOFOLIO_BY_ID,
            payload: res.data.result
        })
    } catch (err) {
        return Promise.reject(err)
    }
}

export {
    getPortofolioById
}