import React from "react";
import styled from "styled-components";
import { Flex, Span } from "../../utils/styles.util";
import Heading from '../shared/heading.component';

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const StyledDiv = styled.div`
    display: flex;
    flex: 1;
`

export default function Service(props){
    return(
        <Flex alignStretch justifyCenter>
            <StyledDiv>
                 {props.left ? <Image src={props.img}/> : 
                 <Flex column justifyCenter alignEnd style={{"margin": "2rem"}}>
                    <Heading alignEnd title={props.title}/>
                    <Span style={{"whiteSpace": "pre-line"}} alignEnd fontPoppinsLight>{props.content}</Span>
                </Flex>}
            </StyledDiv>
            <StyledDiv>
                {props.left ? <Flex column alignStart justifyCenter style={{"margin": "2rem"}}>
                    <Heading alignStart title={props.title}/>
                    <Span style={{"whiteSpace": "pre-line"}} alignStart fontPoppinsLight>{props.content}</Span> </Flex>:  <Image src={props.img}/>
                }  
            </StyledDiv>
        </Flex>
    );
}
