import React from "react";
import "react-step-progress-bar/styles.css";
import "../../styles/customprogressstep.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { COLORS } from "../../utils/constant.util";
import styled, { css } from "styled-components";
import { Flex, Paragraph } from "../../utils/styles.util";

const Line = styled.div`
    height: 2px;
    width: 20%;
    background-color: black;
    ${props => props.accomplished && css`
        background-color: ${COLORS.primary};
    `}
`

const Node = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`

const ProgressBarContainer = styled.div`
    width: 80%;
`

export default function ProgressStep(props){
    return(
        <Flex style={{"width": "100vw", "marginTop": "6rem"}}>
            <Line accomplished/>
            <ProgressBarContainer>
                <ProgressBar
                    percent={props.percent}
                    filledBackground={COLORS.primary}>
                    {props.steps.map((step) => (
                        <Step key={step.no}
                        transition="scale">
                        {({ accomplished }) => (
                            <Flex column spaceBetween alignCenter style={{"width": "1rem"}}
                            id="step_button"
                            onClick={() => props.chooseStep(step)}>
                                <Paragraph medium bold style={{"marginBottom": "1rem"}}>{step.no}</Paragraph>
                                <Node style={{"backgroundColor": `${accomplished ? COLORS.primary : COLORS.text}`}}/>
                                <Paragraph medium bold white>{step.no}</Paragraph>
                            </Flex>
                        )}
                        </Step>
                    ))}
                </ProgressBar>
             </ProgressBarContainer>
            <Line accomplished={props.percent >= 100}/>
        </Flex>
    )
}