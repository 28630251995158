import http from "../utils/http.utils"

class ShoppingService {
    fetchAllProductByCategory(id){
        return http.get(`/shopping/GetAllProductsByCategory/${id}`);
    }
    fetchById(id){
        return http.get(`/shopping/GetProductsById/${id}`)
    }
    fetchAllProduct(){
        return http.get('/shopping/GetAllProducts');
    }
    deleteProduct(id){
        return http.delete(`/shopping/DeleteProduct/${id}`);
    }

    submitProduct(data){
       
        let formData = new FormData()
        formData.append("id",data.id)
        formData.append("titleProduct",data.titleProduct)
        formData.append("description",data.description)
        formData.append("stock",data.stock)
        formData.append("price",data.price)
        formData.append("categoryProductId",data.categoryProductId)

        if(data.id === -1){
            let selectedFiles = data.selectedFiles.map((data) => data.file)
            let files = [
                data.mainImage,
                ...selectedFiles
            ]
            files.forEach((file) => {
                formData.append("files", file)
            })
        }
        else{
            formData.append("deleted", data.deletedAssetsParam)

            let newAddedImages = data.selectedFiles
            .filter((data) => data.id === -1)
            .map((data) => data.file)
            newAddedImages.forEach((file) => {
                formData.append("files", file)
            })

        }

        return http.post(`/shopping/SubmitProduct`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })

    }
    
}

export default new ShoppingService()