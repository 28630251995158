import React, { Component } from 'react';
import Navbar from '../shared/navbar.component';
import Footer from '../shared/footer.component';
import BackgroundHeader from '../../assets/bg_header_category_product.jpg';
import Backdrop from '../shared/backdrop.component';
import { Container,Row } from 'react-bootstrap';
import { getAllCategoryProduct } from "../../middleware/categoryproduct"
import { connect } from "react-redux";
import CategoryBox from './categorybox.component';
import { Helmet } from 'react-helmet';

class CategoryProduct extends Component{
    constructor(props){
        super(props)
        this.state = {
            category: null
        }
    }

    async componentDidMount(){
        await this.props.getAllCategoryProduct()
        let data = this.props.data
        data!== undefined && this.setState({
            category: <CategoryBox content={data}></CategoryBox>
        })
    }

    render(){
        return(
            <>
             <Helmet>
                <title>Category Shopping</title>
            </Helmet>
            <Navbar/>
            <Backdrop image={BackgroundHeader} title="Category Shopping"/>
            <div style={{ "marginBottom": "8rem","marginTop": "8rem"}}>
                <Container>
                <Row className="justify-content-md-center" style={{"marginTop": "3rem", "justifyContent": "center"}}>
                    {this.state.category !== null && this.state.category}
                </Row>
                </Container>
            </div>
            <Footer/>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        data: state.categoryProductReducer
    }
}
export default connect(mapStateToProps, { getAllCategoryProduct })(CategoryProduct);