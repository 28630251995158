import styled from "styled-components";

const Card = styled.div`
    box-sizing: border-box;
    background:white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2rem 4rem rgba(0,0,0,0.4);
    padding:1.6rem;
    border-radius:30px;
`;

export {
    Card
}