import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Span } from "../../utils/styles.util";
import { formatRupiah } from "../../utils/function.util";
const Image = styled.img`
    width: 100%;
    height:14rem;
    object-fit: cover;    
    border-radius: 6px;
    @media (max-width: 900px){
        width: 50vw;
        height: 30vh;
    }
`
const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    @media (max-width: 900px){
        display: flex;
        flex-direction: column;
    }
`

const DivGird = styled.div`
    max-width:20vw;
    margin:2rem;
    @media (max-width: 900px){
        max-width: 50vw;
    }
`

function ShoppingBox(props){
    return(
        <Grid>
        {props.content.map((prop,i)=>
            <DivGird key={i}>
            <Link to={{"pathname": "/product/"+prop.id}} style={{textDecoration: 'none' }}> 
                <Image src={prop.Product_Details[0].imageURL}/>
                <Span style={{
                    overflow:"hidden",
                    display:"-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    lineHeight: "1.2em",
                    marginTop: "1rem"
                    }} fontFuturaCondensedMedium big>{prop.name}</Span>
                <Span fontPoppinsLight small bold>{formatRupiah(prop.price)}</Span>
            </Link>
            </DivGird>
        )}
        </Grid>
        
    )
}

export default ShoppingBox