import swal from "sweetalert"
import { POPUPTYPE } from "./constant.util"

export const showAlert = function(message, type){
    let title, icon
    switch(type) {
        case POPUPTYPE.info:
            title = "Info"
            icon = "warning"
            break
        case POPUPTYPE.success:
            title = "Success"
            icon = "success"
            break
        case POPUPTYPE.error:
            title = "Error"
            icon = "error"
            break
        default:
            break
    }

    return swal({
        title: title,
        text: message,
        icon: icon
    })
}

export const showConfirmAlert = function(message){
    return swal({
        title: "Warning",
        text: message,
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
}

export const formatRupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
    }).format(number)
  }