import React from 'react';
import Navbar from '../shared/navbar.component';
import BackgroundHeader from '../../assets/bg_header_title_our_service.jpg'
import Backdrop from '../shared/backdrop.component';
import imageOne from '../../assets/our services-01.jpeg';
import service2 from '../../assets/our services-02.jpeg';
import service3 from '../../assets/our services-03.jpeg';
import service4 from '../../assets/our services-04.jpeg';
import service5 from '../../assets/our services-05.jpeg';
import Footer from '../shared/footer.component';
import { Helmet } from 'react-helmet';
import Service from './service.component';

function Services(){
    const services = [
        {
            img: imageOne,
            title: "Jasa Interior Design",
            content: "Seorang desainer yang baik mampu mengolah ruang dengan kreatif juga menjawab kebutuhan sekaligus mampu menampilkan karakter personal khas pemilik hunian pada karya yang ia hasilkan.\n\nJika Anda ingin memiliki hunian/kantor yang bagus, nyaman, dan enak dipandang, kami sarankan Anda menggunakan jasa desain interior. Dengan menggunakan jasa desain interior, Anda mendapatkan masukan-masukan desain yang nyaman, fungsional serta material-material terbaru yang mungkin belum pernah Anda ketahui."
        },
        {
            img: service2,
            title: "Jasa Arsitek",
            content: "Arsitek yang handal tentunya telah melewati proses belajar dan pengalaman praktik kerja dengan jam terbang yang telah banyak akan semakin memperkokoh kinerja arsitek dalam mendesain bangunan.\n\nDalam ilmu arsitektur ada tiga unsur prinsip dalam merancang bangunan, yaitu fungsi, struktur, dan estetika. Unsur fungsi berkaitan dengan peruntukkan ruang bangunan dan efektifitas penggunaan model ruangan pada bangunan tersebut. Sedangkan unsur struktur berkenaan dengan konstruksi bangunan yang kuat dan tahan lama. Dari perpaduan fungsi dan struktur ini terakumulasilah estetika yang menimbulkan rasa indah dan kenyamanan. Di sinilah pentingnya menggunakan jasa arsitek, karena mereka dapat memvisualisasikan keinginan owner sehingga bangunan mempunyai nilai yang menyenangkan mata dan pikiran."
        },
        {
            img: service3,
            title: "Jasa Renovasi",
            content: "Renovasi bukan hal sepele dan cukup membutuhkan banyak biaya. Apabila renovasi tidak direncanakan dengan baik, kemungkinan akan terjadi pembengkakan biaya dan hasil renovasi tidak akan sesuai dengan yang kita harapkan.\n\nUntuk itu, sebelum melakukan renovasi, sebaiknya membuat perencanaan –perencanaan sedetail mungkin, menganalisa kebutuhan, fungsi dan tujuan renovasi, serat menyesuaikan anggaran yang tersedia."
        },
        {
            img: service4,
            title: "Jasa Bangun Rumah",
            content: `Ketika membangun sebuah rumah banyak hal yang harus diperhitungkan, mulai dari biaya, desain, perijinan, pemilihan bahan baku dan sebagainya. Tentu saja hal-hal itu akan menguras banyak energi dan pikiran kita, untuk itu ada baiknya kita menggunakan jasa kontraktor, karena kontraktor tentunya lebih mengerti dan kompeten dalam hal pembangunan sebuah rumah/ ruko, sehingga dapat meminimalisir kerugian yang tidak dikehendaki.\n\nMetode kerja yang memiliki standarisasi, dipadukan dengan bahan material berkualitas, dikerjakan oleh para tukang yang ahli dibidangnya masing-masing, serta dikelola menggunakan sistem teknologi terkini dapat membuahkan sebuah karya yang berkualitas dan nyaman untuk hunian`
        },
        {
            img: service5,
            title: "Jasa Bangun Ruko/ Gudang",
            content: "Kami percaya bahwa membangun ruko/Gudang sangat penting bagi investasi usaha Anda. Kesalahan dalam pemilihan kontraktor, material dan jangka waktu pembangunan dapat berdampak pada keuntungan Anda. Maka kami bekerja dengan sepenuh hati untuk mewujudkan bangunan ruko/gudang dengan kualitas terbaik, kokoh, dan selesai tepat waktu."
        }
    ]

    return(
        <>
            <Helmet>
                <title>Services</title>
            </Helmet>
            <Navbar/>
            <Backdrop image={BackgroundHeader} title="Our Services"/>
            <div style={{"marginTop": "8rem", "marginBottom": "8rem"}}>
                {services.map((service,index) => <Service key={index} left={index%2===0} {...service}/>)}
            </div>
            <Footer/>
        </>
    );
}

export default Services;