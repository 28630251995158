import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'

import PortfolioList from './components/portfolio/portfoliolist.component';
import Home from './components/home/home.component';
import AboutUs from './components/aboutus/aboutus.component';
import Services from './components/services/services.component';
import categoryproduct from './components/shopping/categoryproduct.component';
import Contact from './components/contact/contact.component';
import whatsappIcon from './assets/whatsapp.png';
import styled from 'styled-components';
import PortfolioDetail from './components/portfolio/portfoliodetail.component';
import Login from './components/login/login.component';
import ProtectedRoute from './utils/protectedroute.util';
import Dashboard from './components/dashboard/dashboard.component';
import NotFound from './components/notfound/notfound.component';
import { AuthToken } from './utils/constant.util';
import PortofolioForm from './components/portfolio/portofolioform.component';
import PortofolioViewAll from './components/portfolio/portofolioviewall.component';
import ShoppingList from './components/shopping/shoppinglist.component';
import ProductViewAll from './components/shopping/productviewall.component'
import ProductForm from './components/shopping/productform.component'
import ProductDetail from './components/productdetail/productdetail.component';

const WhatsAppIcon = styled.button`
    width: 6rem;
    height: 6rem;
    position: fixed;
    bottom: 3rem;
    right: 1rem;
    z-index: 99;
    background-image: url(${whatsappIcon});
   background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
`

function App(){
  const authToken = window.localStorage.getItem(AuthToken)

  return(
    <Router>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/aboutus" component={AboutUs}/>
          <Route exact path="/services" component={Services}/>
          <Route exact path="/shopping" component={categoryproduct}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/portfolio" component={PortfolioList}/>
          <Route path="/portfolio/:id" component={PortfolioDetail}/>
          <Route path="/shopping/:id" component={ShoppingList}/>
          <Route path="/product/:id" component={ProductDetail}/>
          <Route exact path="/login" component={(props) => {
            if(authToken != null){
              return <Redirect to="/dashboard"/>
            }
            return <Login {...props}/>
          }}/>
          <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
          <ProtectedRoute exact path="/AddPortofolio" component={PortofolioForm}/>
          <ProtectedRoute exact path="/ViewPortofolio" component={PortofolioViewAll}/>
          <ProtectedRoute exact path="/ViewProduct" component={ProductViewAll}/>
          <ProtectedRoute exact path="/AddProduct" component={ProductForm}/>
          <Route path="*" component={NotFound}/>
        </Switch>
        { authToken == null &&
          <WhatsAppIcon 
          onClick={()=>{
            window.open('https://wa.me/+6281317778256?text=Halo+Cascade!', '_blank');
          }} 
          title="Contact Us Through Whatsapp"/>
      }
    </Router>
  );
}

export default App;