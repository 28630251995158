import React from 'react';
import Navbar from '../shared/navbar.component';
import BackgroundHeader from '../../assets/bg_header_title_about_us.jpg'
import Backdrop from '../shared/backdrop.component';
import Footer from '../shared/footer.component';
import AboutCascade from './aboutcascade.component';
import Heading from '../shared/heading.component';
import OurTeam from './ourteam.component';
import '../../styles/aboutus.css'
import { Helmet } from 'react-helmet';


function AboutUs(){
    return(
        <>
         <Helmet>
            <title>About Us</title>
        </Helmet>
        <Navbar/>
        <Backdrop image={BackgroundHeader} title="About Us"/>
        <div style={{ "marginBottom": "8rem","marginTop": "8rem"}}>
            <AboutCascade/>
            <Heading alignCenter title="Our Great Team"/>
            <br/>
            <OurTeam/>
        </div>
        <Footer/>
        </>
    );
}

export default AboutUs;