import React, { Component } from "react";
import Banner from "../shared/banner.component";
import TabComponent from "../shared/tab.component";
import ContentGrid from "../shared/contentgrid.component";
import styled from "styled-components";
import { connect } from "react-redux";
import { getPortofolioById } from "../../middleware/portofoliodetail";
import Navbar from "../shared/navbar.component";
import Footer from "../shared/footer.component";
import { ContentType } from "../../utils/constant.util";
import { Helmet } from 'react-helmet';
const Image = styled.img`
    width: 100%;
    height: 14rem;
    object-fit: cover;    
    border-radius: 6px;
`

const VideoFrame = styled.iframe`
    width: 100%;
    height: 14rem;
    border-radius: 6px;
`

class PortfolioDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            image: null,
            title: "",
            tabData: null
        }
    }

    async componentDidMount(){
        await this.props.getPortofolioById(this.props.match.params.id)
        let data = this.props.data[0]
        data!== undefined && this.setState({
            title: data.title,
            image: data.mainImage,
            tabData: this.generateTabData(data.Portofolio_Details)
        })
    }

    generateTabData(data){
        let images = data.filter((datum) => datum.type === ContentType.image)
            .map((datum) => <Image src={datum.url}/>)
        let videos = data.filter((datum) => datum.type === ContentType.video)
            .map((datum) => <VideoFrame src={datum.url}/>)
        return {
            "titles": ["Foto","Video"], // mungkin bsa direfactor
            "content": [
                <ContentGrid content={images}/>,
                <ContentGrid content={videos}/>
            ]
        }
    }
    
    render(){
        return(
            <>
                <Helmet>
                    <title>Portofolio Detail</title>
                </Helmet>
                <Navbar/>
                <Banner {...this.state}/>
                <div style={{"marginTop": "8rem", "marginBottom": "8rem"}}>
                    {this.state.tabData !== null && <TabComponent {...this.state.tabData}/>}
                </div>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.portofolioDetailReducer
    }
}

export default connect(mapStateToProps, { getPortofolioById })(PortfolioDetail);