import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from '../shared/navbar.component';
import styled from "styled-components";
import { getProductById } from "../../middleware/shopping";
import { COLORS, POPUPTYPE } from "../../utils/constant.util";
import { formatRupiah, showAlert } from "../../utils/function.util";
import { Flex, Paragraph } from "../../utils/styles.util";
import { Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';

const ThumbnailCarousel = styled.div`
    overflow-x: scroll;
    white-space: nowrap;
    width: 20rem;
`

const DisplayImage = styled.img`
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 0.25rem;
`

const ThumbnailImage = styled.img`
    width: 5rem;
    height: 5rem;
    margin: .4rem;
    object-fit: cover;
    border-radius: 0.25rem;
    &:hover {
        cursor: pointer;
    }
`

const StockField = styled.div`
    border: solid 1px ${COLORS.primary};
    padding; .1rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    margin: 0.375rem;
`

const SpecialFlex = styled.div`
    display: flex;
    margin-top: 14vh !important;
    margin-left: 4rem;
    margin-right: 4rem;
`

const SpecialFlexChild = styled.div`
    display: flex;
    margin-top: 14vh !important;

    @media (max-width: 678px){
        flex-direction: column !important;
    }
`

const BottomFlex = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 678px){
        margin-left: 0;
    }

    @media (min-width: 679px){
        margin-left: 2rem;
    }
`
class ProductDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            product: null,
            chosenIndex: 0,
            cart: null
        }
    }

    async componentDidMount(){
        let res = await this.props.getProductById(this.props.match.params.id)
        if(res.data.status === 1 && res.data.product !== undefined && res.data.product !== null){
            this.setState({
                product: res.data.product,
                cart: {
                    currentOrder: 1,
                    minOrder: 1,
                    maxOrder: res.data.product.stock
                }
            })
        } else {
            showAlert(res.message || "Something went wrong, please try again.", POPUPTYPE.error)
        }
    }

    deductFromCart = () => {
        this.setState({
            cart : {
                ...this.state.cart,
                currentOrder: this.state.cart.currentOrder-1
            }
        })
    }

    addToCart = () => {
        this.setState({
            cart: {
                ...this.state.cart,
                currentOrder: this.state.cart.currentOrder+1
            }
        })
    }

    checkout = () => {
        let a = document.createElement("a")
        a.target = "_blank"
        const text = `Halo Cascade! Saya ingin membeli ${this.state.product.name} sebanyak ${this.state.cart.currentOrder} buah.`
        a.href = `https://wa.me/+6281317778256?text=${text}`
        a.click() 
    }

    render(){
        return(
            <>
                <Helmet>
                <title>Shopping Detail</title>
                </Helmet>
                <Navbar/>
                {
                this.state.product &&
                <SpecialFlex style={{"alignItems": "center", "justifyContent": "center"}}>
                    <SpecialFlexChild>
                        <Flex column>
                            <DisplayImage alt="product" src={this.state.product.Product_Details[this.state.chosenIndex].imageURL}/>
                            <ThumbnailCarousel style={{"marginTop": ".4rem"}}>
                                {this.state.product.Product_Details.map((detail, i) => (
                                    <ThumbnailImage 
                                        onClick={() => {this.setState({chosenIndex: i})}}
                                        style={i===0 ? {"marginLeft": "0"} : i===this.state.product.Product_Details.length-1 ? {"marginRight": "0"}: {"margin": ".4rem"}} 
                                        src={detail.imageURL} 
                                        key={i}/>
                                ))}    
                            </ThumbnailCarousel>
                        </Flex>
                        <BottomFlex>
                            <Paragraph big style={{"overflowWrap": "anywhere"}}>{this.state.product.name}</Paragraph>
                            <Paragraph medium fontPoppinsLight bold primary>{formatRupiah(this.state.product.price)}</Paragraph>
                            <Paragraph small style={{"overflowWrap": "anywhere", "maxWidth": "30rem"}}>
                                {this.state.product.description}
                            </Paragraph>
                            {
                                this.state.product.stock > 0 &&
                                <Flex>
                                    <Button 
                                        disabled={this.state.cart.currentOrder === this.state.cart.minOrder}
                                        onClick={this.deductFromCart}>-</Button>
                                    <StockField>{this.state.cart.currentOrder}</StockField>
                                    <Button  
                                        disabled={this.state.cart.currentOrder === this.state.cart.maxOrder}
                                        onClick={this.addToCart}>+</Button>
                                </Flex>
                            }
                            <Button 
                                disabled={this.state.product.stock === 0}
                                onClick={this.checkout}>{this.state.product.stock > 0 ? "Beli Sekarang" : "Stok Habis"}</Button>
                        </BottomFlex>
                    </SpecialFlexChild>
            </SpecialFlex>
        }
            </>
        )
    }
}


export default connect(null, { getProductById })(ProductDetail);