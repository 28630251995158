import { combineReducers } from "redux";
import portfolioReducer from "./portfolio.reducer";
import portofolioDetailReducer from "./portofoliodetail.reducer";
import navbarReducer from "./navbar.reducer";
import loginReducer from "./login.reducer";
import categoryProductReducer from './categoryproduct.reducer';
import shoppingReducer from './shopping.reducer';

export default combineReducers({
    loginReducer,
    portfolioReducer,
    portofolioDetailReducer,
    navbarReducer,
    categoryProductReducer,
    shoppingReducer
})