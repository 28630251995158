import styled, { css } from "styled-components"
import { SIZE, COLORS } from "./constant.util"

const Flex = styled.div`
    display: flex;

    ${props => props.row && css`
        flex-direction: row;
    `}

    ${props => props.column && css`
        flex-direction: column;
    `}

    ${props => props.justifyStart && css`
        justify-content: flex-start;
    `}

    ${props => props.justifyEnd && css`
        justify-content: flex-end;
    `}

    ${props => props.justifyCenter && css`
        justify-content: center;
    `}

    ${props => props.spaceAround && css`
        justify-content: space-around;
    `}

    ${props => props.spaceBetween && css`
        justify-content: space-between;
    `}

    ${props => props.alignStart && css`
        align-items: flex-start;
    `}

    ${props => props.alignEnd && css`
        align-items: flex-end;
    `}

    ${props => props.alignBaseline && css`
        align-items: baseline;
    `}

    ${props => props.alignCenter && css`
        align-items: center;
    `}

    ${props => props.wrap && css`
        flex-wrap: wrap;
    `}
` 

const Span = styled.span`
    color: ${COLORS.text};

    font-family: FuturaMediumBold;
    
    ${props => props.fontPoppinsMedium && css`
        font-family : PoppinsMedium !important;
    `}

    ${props => props.fontPoppinsLight && css`
        font-family : PoppinsLight !important;
    `}

    ${props => props.fontFuturaCondensedMedium && css`
        font-family: FuturaCondensedMedium !important;
    `}

    ${props => props.tiny && css`
        font-size: ${SIZE.tiny};
    `}

    ${props => props.small && css`
        font-size: ${SIZE.small};
    `}

    ${props => props.medium && css`
        font-size: ${SIZE.medium};
    `}

    ${props => props.big && css`
        font-size: ${SIZE.big};
    `}

    ${props => props.bold && css`
        font-weight: bold;
    `}

    ${props => props.primary && css`
        color: ${COLORS.primary};
    `}

    ${props => props.alignStart && css`
        text-align: start;
    `}

    ${props => props.alignEnd && css`
        text-align: end;
    `}

    ${props => props.alignCenter && css`
        text-align: center;
    `}
`

const Centered = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Paragraph = styled.p`
    color: ${COLORS.text};
    font-family: 'Poppins', sans-serif;

    ${props => props.fontPoppinsLight && css`
        font-family : PoppinsLight !important;
    `}

    ${props => props.fontFuturaCondensedMedium && css`
        font-family: FuturaCondensedMedium !important;
    `}

    ${props => props.alignCenter && css`
        text-align: center;
    `}

    ${props => props.alignStart && css`
        text-align: start;
    `}

    ${props => props.alignEnd && css`
        text-align: end;
    `}
    ${props => props.alignJustify && css`
        text-align: justify;
    `}

    ${props => props.tiny && css`
        font-size: ${SIZE.tiny};
    `}
    
    ${props => props.small && css`
        font-size: ${SIZE.small};
    `}

    ${props => props.medium && css`
        font-size: ${SIZE.medium};
    `}

    ${props => props.big && css`
        font-size: ${SIZE.big};
    `}

    ${props => props.bold && css`
        font-weight: bold;
    `}

    ${props => props.light && css`
        font-weight: lighter;
    `}

    ${props => props.white && css`
        color: ${COLORS.white} !important;
    `}

    ${props => props.primary && css`
        color: ${COLORS.primary} !important;
    `}

    ${props => props.centered && css`
        margin: auto;
    `}

`

export {
    Flex,
    Paragraph,
    Span,
    Centered
}