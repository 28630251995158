import React, { Component } from "react";
import Navbar from '../shared/navbar.component';
import Footer from '../shared/footer.component';
import Backdrop from '../shared/backdrop.component'
import BackgroundHeader from '../../assets/bg_header_portolist.jpg'
import TabComponent from "../shared/tab.component";
import { getAllPortfolio } from "../../middleware/portofolio"
import { connect } from "react-redux";
import ContentGrid from "../shared/contentgrid.component";
import PortofolioBox from "./portolistcontent.component";
import { Helmet } from 'react-helmet';
class PortfolioList extends Component {
    constructor(props){
        super(props)
        this.state = {
            tabData: null
        }
    }

    async componentDidMount(){
        await this.props.getAllPortfolio()
        let data = this.props.data
        //refactor tambah empty pages
        data!== undefined && this.setState({
            tabData: this.generateTabData(data)
        })
    }

    generatePortoBox(data, key){
        let targetData = data.find((datum) => datum.category_portofolio_name === key)
        if(targetData !== undefined){
            return targetData
            .Portofolios
            .map((porto) => {
                return <PortofolioBox {...porto}/>
            })  
        }
       return []
    }

    generateTabData(data){
        let tab1 = this.generatePortoBox(data, "Rumah Tinggal")
        let tab2 = this.generatePortoBox(data, "Retail")
        let tab3 = this.generatePortoBox(data, "Kantor")
        let tab4 = this.generatePortoBox(data, "Apartemen")

        return {
            "titles": ["Rumah Tinggal","Retail","Kantor","Apartemen"], // mungkin bsa direfactor
            "content": [
                <ContentGrid content={tab1}/>,
                <ContentGrid content={tab2}/>,
                <ContentGrid content={tab3}/>,
                <ContentGrid content={tab4}/>
            ]
        }
    }

    render(){
        return(
            <>
                <Helmet>
                    <title>Portofolio List</title>
                </Helmet>
                <Navbar/>
                <Backdrop image={BackgroundHeader} title="Portofolio"/>
                <div style={{"marginTop": "8rem", "marginBottom": "8rem"}}>
                    {this.state.tabData !== null && <TabComponent {...this.state.tabData}/>}
                </div>
                <Footer/>
            </>
        )
    }
    
}

const mapStateToProps = (state) => {
    return {
        data: state.portfolioReducer
    }
}

export default connect(mapStateToProps, { getAllPortfolio })(PortfolioList);
