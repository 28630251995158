import React,{ Component } from "react";
import styled from "styled-components";
import { Flex,Paragraph } from "../../utils/styles.util";
import { ProductCategories,POPUPTYPE } from "../../utils/constant.util";
import Loader from "../shared/loader.component";
import { Row,Col,Button } from "react-bootstrap";
import { XCircle } from "react-feather";
import { showAlert } from "../../utils/function.util";
import { submitProduct,getProductById } from "../../middleware/shopping";
import { connect } from "react-redux";

const Container = styled.div`
    width: 100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    background: url('https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80');
    background-size:cover;
`

const SecondContainer = styled.div`
  min-height:100vh;
  min-width:100vw;
  display:flex;
  justify-content:center;
  align-items:center;
`

const Card = styled.div`
    max-width: 70%;
    height:100%;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    margin: 4rem;
`

class ProductForm extends Component{
    constructor(props){
        super(props)
        this.state = {
            messages:[],
            isLoading:false,
            categories: ProductCategories,
            formData:{
                id:props.location.state ?  props.location.state.idpost ? props.location.state.idpost : -1 : -1,
                categoryProductId:"1",
                titleProduct:"",
                description:"",
                stock:"",
                price:"",
                selectedFiles: [],
                previewImages: []
            }
        }
    }

    async componentDidMount(){
      let id = this.state.formData.id
      if(id !== -1 && id !== undefined){
        this.props.getProductById(id)
        .then((data) => {
          let value = data.data
           let categoryProductId = value.product.CategoryProductId ? value.product.CategoryProductId : this.state.categories[0].value
           let previewImages = []
           let existingFilesID = []

           let supportingImages = value.product.Product_Details
           .map((data) => {
            var blob = data.imageURL.slice(0, data.imageURL.size, 'image/png')
            let file = new File([blob], `${data.imageURL}.png`, {type: 'image/png'})
            previewImages.push({id: data.id, url: data.imageURL})
            existingFilesID.push(data.id)
            return {id: data.id, file: file}
          })

          this.setState({
            ...this.state,
            formData: {
              id:id,
              categoryProductId:`${categoryProductId}`,
              titleProduct:value.product.name,
              description:value.product.description,
              stock:value.product.stock,
              price:value.product.price,
              selectedFiles: supportingImages,
              previewImages,
              deletedAssets: [],
              existingFilesID: existingFilesID,
            }
          })
          })
      }
    }

    selectFiles = (event) => {
        let images = this.state.formData.previewImages
        for(let i=0;i<event.target.files.length;i++){
          images.push({id: -1, url:URL.createObjectURL(event.target.files[i])})
        }
        let updatedFiles = this.state.formData.selectedFiles
        let newFiles = Array.from(event.target.files).map((file) =>  {
          return {id:-1, file}
        })
        updatedFiles.push(...newFiles)
        
        this.setState({
          ...this.state,
            formData: {
              ...this.state.formData, 
              selectedFiles: updatedFiles,
              previewImages: images
            }
        })
    }


    deleteImage = (targetImg,i) => {
        let deletedImage = this.state.formData.previewImages.find((img) => targetImg === img)
        URL.revokeObjectURL(deletedImage)
       
        if(targetImg.id !== undefined && targetImg.id !== null && this.state.formData.id !== -1){
          let assets = this.state.formData.deletedAssets
          assets.push(targetImg.id)
          this.setState({
            ...this.state,
            formData: {
              ...this.state.formData,
              deletedAssets: assets
            }
          })
        } 
  
        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData, 
            previewImages: this.state.formData.previewImages.filter((img) => img !== targetImg),
            selectedFiles: this.state.formData.selectedFiles.filter((_,idx) => idx !== i)
          }
        })
      }
    
    handleInputChange = (e) => {
        const { name, value } = e.target
        this.setState({
          ...this.state,
          formData: {...this.state.formData, [name]: value}
        })
      }

      validate = () => {
        const formData = this.state.formData
        if(formData.titleProduct.trim() === ""){
          showAlert("Title must not be empty", POPUPTYPE.info)
          return false
          
        } else if(formData.selectedFiles.length <= 0){
          showAlert("Must choose at least 1 supporting images", POPUPTYPE.info)
          return false
        }
        else if(isNaN(formData.price)){
            showAlert("Price must be number", POPUPTYPE.info)
            return false
        }
        else if(isNaN(formData.stock)){
            showAlert("Stock must be number", POPUPTYPE.info)
            return false
        }
        else if(formData.description.trim() === ""){
            showAlert("Title must not be empty", POPUPTYPE.info)
            return false
        } 
        return true
      }

      handleSubmit = () => {
        if(!this.validate()){ return }
        this.setState({ ...this.state,isLoading: true})
        this.props.submitProduct(this.state.formData)
        .then((res) => {
          this.setState({ ...this.state,isLoading: false})
          if(res.data.status === 1){
            showAlert(res.data.message, POPUPTYPE.success)
            .then(()=>{
              this.props.history.goBack()
            })
          } else {
            showAlert(res.data.message, POPUPTYPE.error)
          }
        }).catch((err) => {
          this.setState({ ...this.state,isLoading: false})
          showAlert(err.message, POPUPTYPE.error)
        })
      }
    render(){
        return (
            <Container>
            <Loader isShown={this.state.isLoading}/>
            <SecondContainer>
                <Card>
                    <Paragraph bold medium alignCenter>{this.state.formData.id=== -1 ? "Add" : "Edit"} Product</Paragraph>
                    <Row>
                        <Col>
                            <label htmlFor="titleProduct">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                required
                                value={this.state.formData.titleProduct}
                                onChange={this.handleInputChange}
                                name="titleProduct"/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                        <Flex column>
                            <label htmlFor="categoryProductId">Category <span style={{"color": "red"}}>*</span></label>
                            <select style={{"padding": ".5rem"}} defaultValue={this.state.formData.categoryProductId} name="categoryProductId" onChange={this.handleInputChange}> 
                            {
                                this.state.categories.map(category => 
                                    <option selected={category.value === this.state.formData.categoryProductId} key={category.name} id="categoryProductId" value={category.value}>{category.name}</option>
                                )
                            }
                            </select>
                        </Flex>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <label htmlFor="description">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="description"
                                required
                                value={this.state.formData.description}
                                onChange={this.handleInputChange}
                                name="description"/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <label htmlFor="price">Price</label>
                            <input
                                type="text"
                                className="form-control"
                                id="price"
                                required
                                value={this.state.formData.price}
                                onChange={this.handleInputChange}
                                name="price"/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <label htmlFor="stock">Stock</label>
                            <input
                                type="text"
                                className="form-control"
                                id="stock"
                                required
                                value={this.state.formData.stock}
                                onChange={this.handleInputChange}
                                name="stock"/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                        <Flex column>
                            <label htmlFor="img">Supporting Images <span style={{"color": "red"}}>*</span></label>
                            <input style={{"color": "white"}} type="file" multiple accept="image/*" onChange={this.selectFiles} />
                        </Flex>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                        {
                        this.state.formData.previewImages && 
                            <Flex wrap="true">
                            {this.state.formData.previewImages.map((img, i) => <ProductPreview key={i} img={img.url} onClick={() => this.deleteImage(img,i)}/>)}
                            </Flex>
                        }
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Button style={{"width": "100%", "margin": "0"}} onClick={this.handleSubmit}>SUBMIT</Button>
                        </Col>
                    </Row>
                </Card>
            </SecondContainer>
            </Container>
        );
    }
}

const Wrapper = styled.div`
  position: relative;
  width: 200px !important;
  height: 200px !important;
  margin: 1rem;
  border-radius: 1rem;
  border: solid 1px;
  overflow: hidden;
`

class ProductPreview extends Component {
  render(){
    return(
      <Wrapper> 
        <XCircle
          style={{"strokeWidth": "2px", "stroke":"red", "position": "absolute", "width": "40px", "height": "40px", "top": "8", "right": "8"}}
          onClick={this.props.onClick}/>
        <img 
          style={{"objectFit": "cover"}}
          width={200}
          height={200}
          src={this.props.img} 
          alt="prod"/>
       </Wrapper>
    )
  }
}
const mapStateToProps = (state) => {
    return {
        data: state.shoppingReducer
    }
}
export default connect(mapStateToProps, { submitProduct,getProductById })(ProductForm);