import React, { PureComponent } from "react";
import ProgressStep from "./progressstep.component";
import { Flex } from "../../utils/styles.util";
import Progress from "./progress.component";
import Heading from "../shared/heading.component";
import wwus1 from "../../assets/wwus1.png";
import wwus2 from "../../assets/wwus2.png";
import wwus3 from "../../assets/wwus3.png";
import wwus4 from "../../assets/wwus4.png";
import wwus5 from "../../assets/wwus5.png";

export default class WorkWithUs extends PureComponent {
    constructor(props){
      super(props)
      this.steps = [
        {
          percent: 0,
          no: "01",
          image: wwus1,
          title: "Design",
          desc: "Di tahap ini kami mendengarkan kebutuhan klien dan menuangkannya ke dalam konsep design dan layout. Terkadang diperlukan juga survey ke lapangan untuk pengukuran dan memiliki gambaran ruang yang baik dalam mendesign. Disini kami akan mengajukan banyak pertanyaan dan memberikan beberapa contoh design untuk membangun chemistry dengan klien untuk mendukung design yang sesuai dengan keinginan."
        },
        {
          percent: 25,
          no: "02", 
          image: wwus2,
          title: "Gambar Kerja",
          desc: "Setelah design fix, kita lanjut ke gambar kerja. Di tahap ini kita akan membahas mengenai bahan/material yang akan digunakan, juga kita akan membuat detail drawing berisikan ukuran2 ruang, ukuran furniture yg akan dibuat, detail plafon, titik lampu dll sehingga tidak ada kesalahan di pengerjaan di lapangan."
        },
        {
          percent: 50,
          no: "03",
          image: wwus3,
          title: "RAB (Rancangan Anggaran Biaya)",
          desc: "Selanjutnya perhitungan RAB, kami membuat perhitungan sesuai dengan design namun tidak menutup kemungkinan kita berdiskusi mengenai alternatif bahan/material yang sesuai dengan budget klien sampai menemukan kecocokan."
        },
        {
          percent: 75,
          no: "04",
          image: wwus4,
          title: "Konstruksi dan instalasi",
          desc: "Pada tahap ini kami merealisasikan gambar rancangan ke design ruang yang sesungguhnya. Tim pengawas kami akan melakukan koordinasi dengan tukang di lapangan sehingga hasil yang dicapai sesuai dengan rencana design. Kami akan membuat time schedule dan memberikan laporan progres berkala kepada klien dan klien dapat berdiskusi dengan kami mengenai progres di lapangan maupun di workshop."
        },
        {
          percent: 100,
          no: "05",
          image: wwus5,
          title: "Tahap akhir dan serah terima",
          desc: "Kepuasan klien adalah prioritas kami, maka daripada itu kami melakukan checklist secara menyeluruh dan mendetail sesuai dengan RAB dan design. Kami juga memberikan garansi selama 1 bulan kepada klien. Seperti itulah tahapan kolaborasi antara cascade dengan klien, segala keputusan akan sama-sama kita diskusikan."
        }
      ]
      this.hasSetInterval = false
      this.interval = null
      this.state = {
          chosenNumber: 0,
          currentElement: null
      }
    }

    componentDidMount() {
      document.addEventListener("scroll", this.isInViewport);
    }

    componentWillUnmount() {
      clearInterval(this.interval)
      document.removeEventListener("scroll", this.isInViewport);
    }

    setupInterval = () => {
      this.interval = setInterval(() => {
        if (this.state.chosenNumber+1 === this.steps.length){
          this.setState({
            chosenNumber: 0
          })
        } else {
          this.setState({
            chosenNumber: this.state.chosenNumber + 1
          });
        }
      }, 8000)
      this.hasSetInterval = true
    }

    isInViewport = () => {
      if(!this.state.currentElement){
        return
      }
      const top = this.state.currentElement.getBoundingClientRect().top;

      if(top <= 600 && !this.hasSetInterval){
          this.setupInterval()
      }
    } 

    handleClickStep = (step) => {
        let targetStep = this.steps.find((curStep) => curStep.no === step.no)
        this.setState({chosenNumber: parseInt(targetStep.no-1)})

        clearInterval(this.interval)
        this.setupInterval()
    }

    render(){
      return(
          <Flex 
            ref={(el) => this.setState({currentElement: el})} 
            column 
            style={{
              "marginTop": "10rem", 
              "marginBottom": "8rem"
              }}>
                <Heading alignCenter title="Work With Us"/>
                <ProgressStep 
                  steps={this.steps} 
                  percent={this.steps[this.state.chosenNumber].percent} 
                  chooseStep={(step) => this.handleClickStep(step)}/>
                <Progress {...this.steps[this.state.chosenNumber]}/>
          </Flex>
      );
    }
}