import React, { useEffect, useState } from "react";
import Heading from "../shared/heading.component"
import fastIcon from '../../assets/fast.png';
import freeDesignIcon from '../../assets/freedesign.png';
import guaranteeIcon from '../../assets/guarantee.png';
import trustedIcon from '../../assets/trusted.png';
import qualityIcon from '../../assets/quality.png';
import Advantage from "./advantage.component";
import styled from "styled-components";
import professionalIcon from '../../assets/professional.png';
import { Container, Col, Row } from "react-bootstrap";
import blocks from '../../assets/blocks.png';

const OuterFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    background-image: linear-gradient(white, #F5F5F5);
    padding-bottom:12rem;
    position: absolute;
    z-index: 2;
`

const Blocks = styled.img`
    width: 100vw;
    z-index: 1;
    bottom: 0;
    left:0;
    position: absolute;
    object-fit: cover;
    object-position: 100% 100%;
`

const Wrapper = styled.div`
    margin-top: 8rem;
    position: relative !important;
    transition: all .5s;
`

function OurAdvantages(){
    const [containerHeight, setContainerHeight] = useState(0)
    const [targetDiv, setTargetDiv] = useState(null)

    const debounce = (fn, d) => {
        let timer;
        return function() {
          let context = this;
          let args = arguments;
          clearTimeout(timer);
          timer = setTimeout(() => {
            fn.apply(context, args);
          }, d);
        }
    }

    useEffect(()=>{
        const updateHeight = () => {
            setContainerHeight(targetDiv != null ? targetDiv.offsetHeight: 0);
        }
        setTimeout(() => {
            window.addEventListener("resize", debounce(updateHeight,300));
            updateHeight();
        }, 800);

        return () => {
            window.removeEventListener("resize", debounce(updateHeight, 300));
        };
    },[targetDiv])

    const advantages = [
        {
            title: "Cepat",
            desc: "Tim kami tentunya telah memiliki pengalaman yang mendalam yang dapat memastikan kami dapat menyelesaikan proyek dengan cepat dan tepat waktu",
            image: fastIcon
        },
        {
            title: "Terpercaya",
            desc: "Kami telah dipercaya oleh banyak klien untuk mengerjakan proyek mereka, yang tentunya dapat dilihat sendiri dari beberapa portfolio yang telah dilampirkan",
            image: trustedIcon
        },
        {
            title: "Free Design",
            desc: "Kami akan memberikan free design untuk seluruh bangunan impian sesuai yang anda harapkan",
            image: freeDesignIcon
        },
        {
            title: "Tenaga Profesional",
            desc: "Tim kami merupakan tim-tim profesional yang memang sudah lama bekerja di bidangnya. Dari design-design yang sesuai dengan keinginan klien hingga hasil jadi yang tentunya akan memuaskan klien juga",
            image: professionalIcon
        },
        {
            title: "Bahan Berkualitas",
            desc: "Dalam pemilihan bahan, tentunya kami sudah memilihkan bahan-bahan yang terjamin berkualitas, sekalipun dengan budget minim, kami tetap akan memberikan bahan terbaik versi kami",
            image: qualityIcon
        },
        {
            title: "Bergaransi",
            desc: "Kami akan memberikan garansi untuk setiap proyek yang kami kerjakan demi kenyamanan klien, sehingga kami akan selalu bertanggung jawab dengan apa yang telah kami hasilkan",
            image: guaranteeIcon
        }
    ]

    let upperAdvantages = advantages.slice(0,3);
    let lowerAdvantages = advantages.slice(3,6);
    
    return(
        <Wrapper style={{"height":containerHeight}}>
            <OuterFlex id="target_node" ref={(node) => setTargetDiv(node)}>
                <Heading alignCenter title="Kelebihan Kami"/>
                <Container>
                    <Row>
                        {upperAdvantages.map((advantage) => (
                            <Col key={advantage.title}>
                                <Advantage image={advantage.image} title={advantage.title} desc={advantage.desc}/>
                            </Col>    
                        ))}
                    </Row>
                    <Row>
                        {lowerAdvantages.map((advantage) => (
                            <Col key={advantage.title}>
                                <Advantage image={advantage.image} title={advantage.title} desc={advantage.desc}/>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </OuterFlex>
            <Blocks style={{"height": 0.15*containerHeight}} src={blocks} alt="blocks"/>
        </Wrapper>
    )
}

export default OurAdvantages;