import { GET_ALL_CATEGORY_PRODUCT } from "../actions/types";
const initialState = []

function categoryProductReducer(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_ALL_CATEGORY_PRODUCT:
            return payload
        default:
            return state
    }
}

export default categoryProductReducer