import React from 'react';
import Navbar from '../shared/navbar.component';
import Carousel from './carousel.component';
import Footer from '../shared/footer.component';
import OurAdvantages from './ouradvantages.component';
import WorkWithUs from './workwithus.component';
import { Helmet } from 'react-helmet';
function Home(){
    return(
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Navbar/>
            <Carousel/>
            <OurAdvantages/>
            <WorkWithUs/>
            <Footer/>
        </>
    );
}

export default Home;