import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../utils/constant.util';
  
const SpinningAnimation = keyframes`
0% {
    transform: rotate(0deg);
}

100% {
    transform: rotate(360deg);
}
`;

const LoaderContainer = styled.div`
    position: fixed;
    z-index:9999;
    background:#D3D3D3;
    padding:1.4rem;
    border-radius: 30px;
    box-shadow: 1rem 1rem 2rem rgba(0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const ProgressBar = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: .7rem solid ${COLORS.text};
    border-top: .7rem solid white;
    animation: ${SpinningAnimation} 1s ease-in-out infinite;
`

const Loader = (props) => {
    return (
        <LoaderContainer style={{"opacity": `${props.isShown ? 1 : 0}`}}>
            <ProgressBar />
        </LoaderContainer>
    )
}

export default Loader;