import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from '../reducers/root.reducer';

const initialState = {};

export default createStore(
    rootReducer,
    initialState, 
    composeWithDevTools(applyMiddleware(thunk))
)