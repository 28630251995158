import http from '../utils/http.utils'

class PortfolioService {
    fetchAll(){
        return http.get('/GetAllExample');
    }
    fetchById(id){
        return http.get(`/portofolio/GetDetailPortofolioById/${id}`)
    }
    fetchPortofolioList(){
        return http.get('/portofolio/GetAllPortofolioByCategories')
    }
    deletePortofolio(id){
        return http.delete(`/portofolio/DeletePortofolio/${id}`)
    }
    submitPortofolio(data){
        let formData = new FormData()
        formData.append("id", data.id)
        formData.append("title", data.title)
        formData.append("portofolioCategoryId", data.type)
        formData.append("videoURLString", data.videoURLParam)
           
        if(data.id === -1){
            let selectedFiles = data.selectedFiles.map((data) => data.file)
            let files = [
                data.mainImage,
                ...selectedFiles
            ]
            files.forEach((file) => {
                formData.append("files", file)
            })
        } else {   
            let fileParam = []
            formData.append("deleted", data.deletedAssetsParam)
            formData.append("updatedVideoURLs", data.updatedVideoURLs)   
            formData.append("hasChangedMainFile", data.hasChangedMainFile ? 1 : 0)

            let newAddedImages = data.selectedFiles
            .filter((data) => data.id === -1)
            .map((data) => data.file)

            if(data.hasChangedMainFile){
                fileParam = [
                    data.mainImage,
                    ...newAddedImages
                ]
            } else {
                fileParam = newAddedImages
            }
            fileParam.forEach((file) => {
                formData.append("files", file)
            })
        }

        return http.post(`/portofolio/SubmitPortofolio`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}

export default new PortfolioService()