import { Component } from "react";
import Loader from "../shared/loader.component";
import styled from "styled-components";
import { Paragraph } from "../../utils/styles.util";
import { Row,Col } from "react-bootstrap";
import {  MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import { getAllProduct,deleteProduct } from "../../middleware/shopping";
import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import { showAlert, showConfirmAlert } from "../../utils/function.util";
import { POPUPTYPE } from "../../utils/constant.util";

const Container = styled.div`
  width: 100vw;
  display:flex;
  align-items: center;
  justify-content: center;
  background: url('https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80');
  background-size:cover;
`


const SecondContainer = styled.div`
  min-height:100vh;
  min-width:100vw;
  display:flex;
  justify-content:center;
  align-items:center;
`

const Card = styled.div`   
    max-width: 100%;
    height:100%;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    margin: 4rem;
`

class ProductViewAll extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading :false,
            product:null
        }
    }

    async componentDidMount(){
        await this.props.getAllProduct()
        let data = this.props.data
    
        data !== undefined && this.setState({
            product:this.generateProduct(data)
        })
    }

    deleteProduct(id,name){
        showConfirmAlert(`Are you sure want to delete ${name} ?`).then((res)=>{
            if(res===true){
                this.setState({isLoading:true})
                this.props.deleteProduct(id)
                .then((res)=>{
                    this.setState({isLoading:false})
                    if(res.data.status === 1){
                        this.componentDidMount()
                        showAlert(res.data.message,POPUPTYPE.success)
                    }
                    else{
                        showAlert(res.data.message,POPUPTYPE.error)
                    }
                })
            }
        })
    }

    generateProduct(data){
        var jsonArr = [];
        
        for(let i =0;i< data.length;i++){
            for(let j=0; j < data[i].Products.length;j++){
                jsonArr.push({
                    name: data[i].Products[j].name,
                    category: data[i].name,
                    stock:data[i].Products[j].stock,
                    price:data[i].Products[j].price,
                    action:
                    <>
                      <Link
                        to={{
                          pathname: "/AddProduct",
                          state: {
                            idpost: data[i].Products[j].id
                        },
                        }}>
                          <Icon.Edit/>
                      </Link>
                      <Icon.Delete onClick={() => this.deleteProduct(data[i].Products[j].id,data[i].Products[j].name)}/>
                    </>
                });
            }
        }

        const dataTable = {
            columns: [
              {
                label: 'Name',
                field: 'name',
                width: 150,
                attributes: {
                  'aria-controls': 'DataTable',
                  'aria-label': 'Name',
                },
              },
              {
                label: 'Category',
                field: 'category',
                width: 270,
              },
              {
                label: 'Stock',
                field: 'stock',
                width: 270,
              },
              {
                label: 'Price',
                field: 'price',
                width: 270,
              },
              {
                label: 'Action',
                field: 'action'
              },
            ],
            rows:jsonArr,
          }


          return dataTable
    }

    
    render(){
        return (
            <Container>
                <Loader isShown={this.state.isLoading}/>
                <SecondContainer>
                    <Card>
                        <Paragraph bold medium alignCenter>View All Product</Paragraph>
                        <Row>
                            <Col>
                                {
                                    this.state.product !== null &&
                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 10, 15]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={this.state.product}
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                    />
                                }
                            </Col>
                        </Row>
                    </Card>
                </SecondContainer>
            </Container>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        data:state.shoppingReducer
    }
}

export default connect(mapStateToProps,{getAllProduct,deleteProduct})(ProductViewAll)