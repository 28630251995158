import { NAVBAR_CHOOSE_SECTION } from "../redux/actions/types"

const chooseSection = (section) => async (dispatch) => {
    dispatch({
        type: NAVBAR_CHOOSE_SECTION,
        payload: section
    })
}

export {
    chooseSection
}