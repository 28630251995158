import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Centered, Flex, Paragraph } from "../../utils/styles.util";
import background from '../../assets/bg.jpeg';
import { Link } from "react-router-dom";

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.menus = [
            {
                "name": "Lihat Semua Portofolio",
                "route": "/ViewPortofolio"
            },
            {
                "name": "Tambah Portofolio Baru",
                "route": "/addportofolio"
            },
            {
                "name": "Lihat Semua Product",
                "route": "/ViewProduct"
            },
            {
                "name": "Tambah Product Baru",
                "route": "/AddProduct"
            }
        ]
    }

    render(){
        return(
            <div style={{
                 "backgroundImage": `url(${background})`,
                 "backgroundSize": "cover",
                 "width": "100vw",
                 "height": "100vh"
                 }}>
                <Centered style={{"width": "80%"}}>
                <Flex column alignCenter>
                    <Paragraph big bold alignCenter>Selamat Datang, Admin</Paragraph>
                    <Flex wrap="true" spaceAround style={{"width": "50%"}}>
                        {this.menus.map((menu) => (
                            <Link to={menu.route} key={menu.route}>
                                <Button
                                 style={{
                                    "borderRadius": "16",
                                    "fontWeight": "bold",
                                    "padding": "1rem",
                                    "marginTop": "2rem"
                                }}
                                >{menu.name}</Button>
                            </Link>
                        ))}

                        <button
                        className="btn btn-danger"
                        onClick={()=>{
                            localStorage.clear();
                            window.location.href = window.location.origin+"/login";
                        }}
                            style={{
                            "borderRadius": "16",
                            "fontWeight": "bold",
                            "padding": "1rem",
                            "marginTop": "2rem"
                            }}>
                                Logout
                        </button>
                    </Flex>
                </Flex>
                </Centered>
            </div>
        )
    }
}

export default Dashboard