import React from "react";
import styled from "styled-components";
import { Flex, Paragraph } from "../../utils/styles.util";
import { COLORS } from "../../utils/constant.util";

/*
    Usage: <Heading title="Home"/>
**/

const UpperLine = styled.span`
    height: 0.5rem;
    background-color: ${COLORS.primary};
    width: 3.7rem;
`

export default function Heading(props){
    return(
        <Flex column alignCenter={props.alignCenter || false} alignStart={props.alignStart || false} alignEnd={props.alignEnd || false}>
            <UpperLine/>
            <Paragraph alignCenter={props.alignCenter || false} alignStart={props.alignStart || false} alignEnd={props.alignEnd || false} big bold>{props.title || "Forgot to set props title"}</Paragraph>
        </Flex>
    )
}