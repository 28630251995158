import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { submitPortofolio, getPortofolioData } from "../../middleware/portofolio";
import { POPUPTYPE, PortofolioCategories } from "../../utils/constant.util";
import { showAlert } from "../../utils/function.util";
import { Flex, Paragraph } from "../../utils/styles.util";
import Loader from "../shared/loader.component";
import styled from 'styled-components';
import { XCircle } from 'react-feather';


const Container = styled.div`
    width: 100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    background: url('https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80');
    background-size:cover;
`

const SecondContainer = styled.div`
  min-height:100vh;
  min-width:100vw;
  display:flex;
  justify-content:center;
  align-items:center;
`

const Card = styled.div`
    max-width: 70%;
    height:100%;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    margin: 4rem;
`

class PortofolioForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            messages: [],
            isLoading: false,
            categories: PortofolioCategories,
            formData: {
              id: props.location.state ?  props.location.state.idpost ? props.location.state.idpost : -1 : -1 ,
              title: "",
              type: '1',
              videoURLs: [{id:-1, url: ""}],
              mainImage: null,
              selectedFiles: [],
              previewImages: []
            },
        }
    }

    selectFiles = (event) => {
        let images = this.state.formData.previewImages
        for(let i=0;i<event.target.files.length;i++){
          images.push({id: -1, url:URL.createObjectURL(event.target.files[i])})
        }
        let updatedFiles = this.state.formData.selectedFiles
        let newFiles = Array.from(event.target.files).map((file) =>  {
          return {id:-1, file}
        })
        updatedFiles.push(...newFiles)
        
        this.setState({
          
            formData: {
              ...this.state.formData, 
              selectedFiles: updatedFiles,
              previewImages: images
            }
        })
    }
    
    selectMainImage = (event) => {
      this.setState({
        formData: {
          ...this.state.formData,
          mainImage: event.target.files[0],
          hasChangedMainFile: true
        },
      })
    }

    deleteImage = (targetImg,i) => {
      let deletedImage = this.state.formData.previewImages.find((img) => targetImg === img)
      URL.revokeObjectURL(deletedImage)
     
      if(targetImg.id !== undefined && targetImg.id !== null && this.state.formData.id !== -1){
        let assets = this.state.formData.deletedAssets
        assets.push(targetImg.id)
        this.setState({
          formData: {
            ...this.state.formData,
            deletedAssets: assets
          }
        })
      } 

      this.setState({
        formData: {
          ...this.state.formData, 
          previewImages: this.state.formData.previewImages.filter((img) => img !== targetImg),
          selectedFiles: this.state.formData.selectedFiles.filter((_,idx) => idx !== i)
        }
      })
    }

    async componentDidMount(){
      let id = this.state.formData.id
      if(id!==-1 && id !== undefined){
        this.props.getPortofolioData(id)
          .then((data) => {
            let type = data.CategoryPortofolioId ? data.CategoryPortofolioId : this.state.categories[0].value
            let previewImages = []

            let existingFilesID = []
            let supportingImages = data.Portofolio_Details
            .filter((details) => details.type === 1)
            .map((data) => {
              var blob = data.url.slice(0, data.url.size, 'image/png')
              let file = new File([blob], `${data.url}.png`, {type: 'image/png'})
              previewImages.push({id: data.id, url: data.url})
              existingFilesID.push(data.id)
              return {id: data.id, file: file}
            })

            let videos = data.Portofolio_Details
            .filter((details) => details.type === 2)
            
            var mainImageBlob = data.mainImage.slice(0, data.mainImage.size, 'image/png')
            let mainImageFile = new File([mainImageBlob], `${data.mainImage}.png`, {type: 'image/png'})

            this.setState({
              formData: {
                id: id,
                type: `${type}`,
                title: data.title,
                mainImage: mainImageFile,
                selectedFiles: supportingImages,
                videoURLs: videos,
                previewImages,
                deletedAssets: [],
                existingFilesID: existingFilesID,
                hasChangedMainFile: false
              }
            })
          })
      }
    }

    handleInputChange = (e) => {
      const { name, value } = e.target
      this.setState({
        formData: {...this.state.formData, [name]: value}
      })
    }

    handleVideoButtonAdd = () => {
      let videoURLs = this.state.formData.videoURLs
      videoURLs.push({id: -1,url:''})
      this.setState({
        formData: {
          ...this.state.formData,
          videoURLs
        }
      })
    }

    handleVideoTextFieldChanges = (content, i) => {
      let videoURLs = this.state.formData.videoURLs
      videoURLs[i].url = content
      this.setState({
        formData: {
          ...this.state.formData,
          videoURLs
        }
      })
    }

    handleDeleteVideoFields = (id, targetIdx) => {
      let videoURLs = []
      if(id !== -1){
        videoURLs = this.state.formData.videoURLs.filter((video) => video.id !== id)
      } else {
        videoURLs = this.state.formData.videoURLs.filter((_,index) => index !== targetIdx)
      }
      if(this.state.formData.id !== -1){
        let assets = this.state.formData.deletedAssets
        assets.push(id)
        this.setState({
          
          formData: {
            ...this.state.formData,
            deletedAssets: assets
          }
        })
      }
      this.setState({
        
        formData: {
          ...this.state.formData,
          videoURLs
        }
      })
    }

    validate = () => {
      const formData = this.state.formData
      if(formData.title.trim() === ""){
        showAlert("Title must not be empty", POPUPTYPE.info)
        return false
      } else if(formData.mainImage === null || formData.mainImage === undefined){
        showAlert("Must choose main image first", POPUPTYPE.info)
        return false
      } else if(formData.selectedFiles.length <= 0){
        showAlert("Must choose at least 1 supporting images", POPUPTYPE.info)
        return false
      }
      return true
    }

    handleSubmit = () => {
      if(!this.validate()){ return }
      this.setState({ isLoading: true})
      this.props.submitPortofolio(this.state.formData)
      .then((res) => {
        this.setState({ isLoading: false})
        if(res.data.status === 1){
          showAlert(res.data.message, POPUPTYPE.success)
          .then(()=>{
            this.props.history.goBack()
          })
        } else {
          showAlert(res.data.message, POPUPTYPE.error)
        }
      }).catch((err) => {
        this.setState({ isLoading: false})
        showAlert(err.message, POPUPTYPE.error)
      })
    }

    getShortenedName = (name) => {
      return name.length > 200 ? name.substring(0, 199) + "..." : name
    }

    render(){
      return (
        <Container>
        <Loader isShown={this.state.isLoading}/>
        <SecondContainer>
        <Card>
          <Paragraph bold medium alignCenter>{this.state.formData.id === -1 ? "Add" : "Edit"} Portofolio</Paragraph>
          <Row>
            <Col>
              <label htmlFor="title">Title</label>
              <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={this.state.formData.title}
                  onChange={this.handleInputChange}
                  name="title"/>
              </Col>
          </Row>
          <br/>
          <Row>
            <Col>
                <Flex column>
                      <label htmlFor="type">Category <span style={{"color": "red"}}>*</span></label>
                      <select style={{"padding": ".5rem"}} defaultValue={this.state.formData.type} name="type" onChange={this.handleInputChange}> 
                      {
                          this.state.categories.map(category => 
                            <option selected={category.value === this.state.formData.type} key={category.name} id="type" value={category.value}>{category.name}</option>
                          )
                      }
                      </select>
                  </Flex>
              </Col>
          </Row>
          <br/>
          <Row>
            <Col>
                <Flex column>
                  <label htmlFor="mainImage">Main Image <span style={{"color": "red"}}>*</span></label>
                  <Flex wrap="true" alignCenter>
                    <input style={{"color": "white", "width": "110px"}} type="file" name="mainImage" accept="image/*" onChange={this.selectMainImage} />
                    <span>{this.state.formData.mainImage && this.getShortenedName(this.state.formData.mainImage.name)}</span>
                  </Flex>
                </Flex>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
                <Flex column>
                  <label htmlFor="type">Supporting Images <span style={{"color": "red"}}>*</span></label>
                  <input style={{"color": "white"}} type="file" multiple accept="image/*" onChange={this.selectFiles} />
                </Flex>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              {
              this.state.formData.previewImages && 
                <Flex wrap="true">
                {this.state.formData.previewImages.map((img, i) => <PortofolioPreview key={img.id} img={img.url} onClick={() => this.deleteImage(img,i)}/>)}
                </Flex>
              }
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <Flex style={{"width": "100%", "marginBottom": ".4rem"}} spaceBetween alignCenter>
                <label htmlFor="video">Video Links</label>
                <Button onClick={this.handleVideoButtonAdd}>+</Button>
              </Flex>
            </Col>
          </Row>
          <Row>  
            <Flex column style={{"width": "100%"}}>
            {this.state.formData.videoURLs && this.state.formData.videoURLs.map((video,i)=>(
              <Col key={i} style={{"marginTop": ".4rem"}}>
                <Flex alignCenter>
                  <input
                  type="text"
                  className="form-control"
                  required
                  value={video.url}
                  onChange={(e) => this.handleVideoTextFieldChanges(e.target.value, i)}
                  name="video"/>
                  <Button style={{"marginLeft": "1rem"}} className="btn btn-danger" onClick={() => this.handleDeleteVideoFields(video.id,i)}>-</Button>
                </Flex>
              </Col>
            ))}
            </Flex>
          </Row>
          <br/>
          <Row>
            <Col>
                <Button style={{"width": "100%", "margin": "0"}} onClick={this.handleSubmit}>SUBMIT</Button>
            </Col>
          </Row>
        </Card>
        </SecondContainer>
        </Container>
      );
    }
}

const Wrapper = styled.div`
  position: relative;
  width: 200px !important;
  height: 200px !important;
  margin: 1rem;
  border-radius: 1rem;
  border: solid 1px;
  overflow: hidden;
`

class PortofolioPreview extends Component {
  render(){
    return(
      <Wrapper> 
        <XCircle
          style={{"strokeWidth": "2px", "stroke":"red", "position": "absolute", "width": "40px", "height": "40px", "top": "8", "right": "8"}}
          onClick={this.props.onClick}/>
        <img 
          style={{"objectFit": "cover"}}
          width={200}
          height={200}
          src={this.props.img} 
          alt="porto"/>
       </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        data: state.portofolioReducer
    }
}

export default connect(mapStateToProps, { submitPortofolio, getPortofolioData })(PortofolioForm);