import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Flex, Paragraph, Span } from "../../utils/styles.util";
import '../../styles/progress.css';

const Image = styled.img`
    height: auto;
    max-width: 100%;
    object-fit: cover;
`

export default function Progress(props){
    return(
        <Container>
            <Row style={{"marginTop": "6rem"}} id="custom_row">
                <Col>
                    <Image src={props.image} fluid/>
                </Col>
                <Col>
                    <Flex column alignStart>
                        <Span big primary bold>{props.no}</Span>
                        <Paragraph medium>{props.title}</Paragraph>
                        <Span fontPoppinsLight style={{"textAlign": "justify"}} small>{props.desc}</Span>
                    </Flex>
                </Col>
            </Row>
        </Container>
        
    )
}

