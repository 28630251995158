import React from 'react';
import Navbar from '../shared/navbar.component';
import BackgroundHeader from '../../assets/bg_header_contactus.jpg'
import Backdrop from '../shared/backdrop.component'
import Heading from '../shared/heading.component'
import { Col, Container, Row } from 'react-bootstrap';
import { Paragraph, Span } from '../../utils/styles.util'
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom'
import styled from "styled-components";
import { Helmet } from 'react-helmet';
const DivFooterParent  = styled.div`
    background-color:#e3e3e3;
    clip-path: polygon(0% 45%, 100% 0, 100% 100%, 0% 100%, 0 44%);
    -webkit-clip-path:polygon(-50% 45%, 100% 0, 100% 100%, 0% 100%, 0 44%);
    padding-top:7em;
`

function Contact(){

    const locationLink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6232027935134!2d106.8381255812521!3d-6.181159979315017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f434299f30a3%3A0x4a56682522472406!2sJl.%20Prajurit%20KKO%20Usman%20dan%20Harun%20No.30a%2C%20RT.1%2FRW.5%2C%20Senen%2C%20Kec.%20Senen%2C%20Kota%20Jakarta%20Pusat%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2010410!5e0!3m2!1sen!2sid!4v1638462070359!5m2!1sen!2sid"
    return(
        <>
         <Helmet>
            <title>Contact Us</title>
        </Helmet>
        <Navbar/>
        <Backdrop image={BackgroundHeader} title="Contact Us"/>
        <div style={{"marginTop": "5rem", "marginBottom": "5rem"}}>
            <Heading alignCenter title="Hubungi Kami Sekarang"/>
            <br/><br/>
            <Container>
                <Row>
                    <Col>
                        <Span fontFuturaCondensedMedium medium bold>Our Office</Span>
                        <br/>
                        <Icon.MapPin />
                        <Span fontPoppinsLight small> Jl. Prajurit KKO Usman dan Harun No.30A, Senen, Jakarta Pusat, 10110</Span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br/>
                        <iframe src={locationLink} title="Cascade Office Location" style={{"border":"0","height":"20rem","width":"100%"}} allowFullScreen="" loading="lazy"></iframe>
                    </Col>
                    <Col>
                        <br/>
                        <Paragraph small bold>Customer Service</Paragraph>
                        <Span fontPoppinsLight small>Call/Whatsapp</Span>
                        <Span fontPoppinsLight small>
                        <Link to={{"pathname": "https://wa.me/+6281317778256?text=Halo+Cascade!"}} target="_blank"> +6281317778256</Link>
                        </Span>
                        <br/><br/>
                        <Span>
                            <Link to={{ pathname: "https://instagram.com/cascadeinteriorcontractor" }} target="_blank" style={{ color: 'black' }} >
                                <Icon.Instagram />
                            </Link> 
                        </Span>
                    </Col>
                </Row>
            </Container>
        </div>
        <DivFooterParent>
            <Container>
                <Row>
                    <Col style={{"width":"50%"}}>
                        <br/><br/>
                        <Paragraph big>Design dan kualitas terbaik untuk Anda</Paragraph>
                        <Paragraph small>Kepuasan Anda menjadi prioritas kami. Cascade sudah berpengalaman lebih dari sepuluh tahun. Sudah ratusan proyek yang kami kerjakan.</Paragraph>
                        <Paragraph>
                            Kami akan selalu memberikan pelayanan terbaik untuk wujudkan impian Anda membangun hunian/kantor yang nyaman sesuai dengan keinginan Anda.
                        </Paragraph>
                    </Col>
                </Row>
            </Container>
        </DivFooterParent>
        
        </>
    );
}

export default Contact;