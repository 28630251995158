import styled from 'styled-components';
import AboutUs1Img from '../../assets/about_us_1.jpg'
import AboutUs2Img from '../../assets/about_us_2.jpg'
import { Flex, Span,Paragraph } from "../../utils/styles.util";
import { COLORS } from "../../utils/constant.util";
import { Col, Container, Row } from 'react-bootstrap';


const UpperLine = styled.span`
    height: 0.4rem;
    background-color: ${COLORS.primary};
    width: 3.7rem;
`
const DivParentAboutUs = styled.div`
    background-color:${COLORS.light_grey};
    clip-path: polygon(100% 0%, 100% 76.3%, 69% 84.5%, 0% 100%, 0% 24%);
    padding-top:20rem;
    padding-bottom:20rem;
    position:relative;
    display:flex;
    overflow: hidden;
`

const FirstImageAboutUs = styled.img`
    position: relative;
    display: inline;
    width:22rem;
    height:22rem;
`

const SecondImageAboutus = styled.img`
    position:absolute;
    left:15%;
    top:9%;
    width:25rem;
    height:17rem;
`

export default function AboutCascade(){
    return(
        <DivParentAboutUs>
        <Container>
            <Row id="parent-div-aboutus">
                <Col>
                    <FirstImageAboutUs src={AboutUs1Img}/>
                    <SecondImageAboutus src={AboutUs2Img}/>
                </Col>
                <Col style={{"marginTop":"3rem"}}>
                    <Flex column>
                    <UpperLine/>
                    <div>
                        <Span fontFuturaCondensedMedium big>Cascade</Span>
                        <Span fontPoppinsMedium small>&nbsp;&nbsp;(Arsitektur - Interior - Design & Build)</Span>
                    </div>
                    </Flex>
                    <Paragraph small alignJustify fontPoppinsLight>
                    Cascade merupakan merupakan perusahaan interior - kontraktor, yg bergerak mulai dari jasa design arsitek, jasa design interior, jasa bangun rumah, jasa renovasi rumah, jasa renovasi kantor, jasa bangun gudang, dan jasa interior apartemen, telah berpengalaman dan beroperasi sejak tahun 2014. Cascade telah menjadi partner yang di percaya untuk mewujudkan rumah, kantor, apartemen dari banyak klien.
                    </Paragraph>
                </Col>
            </Row>
        </Container>
    </DivParentAboutUs>
    );
}
