import React from "react";
import logo from "../../assets/logo.png";
import styled from "styled-components";
import Loader from "../../components/shared/loader.component";
import { showAlert } from '../../utils/function.util';
import { COLORS, POPUPTYPE } from "../../utils/constant.util";
import { connect } from "react-redux";
import { login } from '../../middleware/login';
import { Card } from "../shared/card.component";

const Container = styled.div`
    background: url('https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80');
    width:100vw;
    height:100vh;
    display:flex;
    align-items: center;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Input = styled.input`
    padding: .6rem;
    border: 1px solid #999;
    
    margin-bottom:2rem;
    font-size: 1rem;
    border-radius:.4rem;
`;

const Button = styled.button`
    background: linear-gradient(to left, ${COLORS.primary}, ${COLORS.secondary});
    padding: 1rem;
    color: white;
    font-weight: 700;
    width: 100%;
    margin-bottom: .5rem;
    font-size: 1rem;
    border-width: 0;
    border-radius:.4rem;
`;

const Logo = styled.img`
    width: 60%;
    margin-bottom: 1rem;
`;


class Login extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            username: "",
            password: "",
            isLoading: false
        }
    }

    
    postLogin = () => {
        let username = this.state.username
        let password = this.state.password

        if (username.length === 0 || password.length === 0){
            showAlert("Username dan password harus diisi", POPUPTYPE.error)
        } else {
            this.setState({isLoading: true})
            let encodedUsername = window.btoa(username)
            let encodedPassword = window.btoa(password)
            
            this.props.login({username: encodedUsername, password: encodedPassword})
            .then((data) => {
                this.setState({isLoading: false})
                if(data.status === 1){
                    this.props.history.replace("/dashboard")
                } else {
                    showAlert(data.message, POPUPTYPE.error)
                }
            }).catch((e) => {
                this.setState({isLoading: false})
                showAlert(e.message, POPUPTYPE.error)
            })
        }
    }

    render(){
        return (
            <Container>
                <Loader isShown={this.state.isLoading}/>
                <Card>
                    <Logo src={logo} />
                    <Form>
                        <Input
                            id="username"
                            type="username"
                            value={this.state.username}
                            onChange={(e) => this.setState({username: e.target.value})}
                            placeholder="Masukkan username"/>
                        <Input
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={(e) => this.setState({password: e.target.value})}
                            placeholder="Masukkan password"/>
                        <Button type="submit" onClick={this.postLogin}>Login</Button>
                    </Form>
               </Card>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.loginReducer
    }
}

export default connect(mapStateToProps, { login })(Login);