import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthToken } from './constant.util';

function ProtectedRoute({component: Component, ...props }){
    const isAuthenticated = window.localStorage.getItem(AuthToken)
    
    return <Route {...props} render={(prop) => 
        isAuthenticated ? <Component {...prop}/> : <Redirect to="/login"/>
    }/>;
}

export default ProtectedRoute;