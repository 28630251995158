import loginService from "../services/login.service"
import { AuthToken } from "../utils/constant.util"

const login = (data) => async () => {
    try {
        const res = await loginService.login(data)
        if(res.data.status === 1){
            window.localStorage.setItem(AuthToken, res.data.token)
        }
        return Promise.resolve(res.data)
    } catch (err) {
        return Promise.reject(err)
    }
}

export {
    login
}