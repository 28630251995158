import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { chooseSection } from '../../middleware/navbar';
import { COLORS, navbarSections } from "../../utils/constant.util";
import { Container, Nav, Navbar } from 'react-bootstrap';
import '../../styles/navbar.css'
import logo from "../../assets/logo.png"
import styled from "styled-components";
import { Flex, Span } from '../../utils/styles.util';


const Logo = styled.img`
    src: url(${logo});
    height:70px;
`

const Line = styled.span`
    height: 0.2rem;
    background-color: ${COLORS.primary};
    width: 50%;
`

function NavbarComponent(props){
    const leftMenuItems = navbarSections.slice(0,3);
    const rightMenuItems = navbarSections.slice(3,6);

    const handleClick = (section) => {
        props.chooseSection(section)
    }

    const generateMenu = (menuItems) => {
        return(
            <>
            {menuItems.map((item) => (
                <Link 
                    key={item.menuSection} 
                    to={item.route} 
                    className="nav-link my-auto" 
                    onClick={() => handleClick(item.menuSection)}
                    style={{"textAlign": "center"}}>
                        {props.section === item.menuSection ? 
                                <Flex alignCenter column>
                                <Span fontPoppinsMedium>{item.title}</Span>  
                                <Line/>
                            </Flex> : 
                            <Flex alignCenter column>
                                <Span fontPoppinsMedium>{item.title}</Span>
                            </Flex>
                        }    
                </Link>
            ))}
        </>
        )
    }
    return(
        <Navbar collapseOnSelect fixed="top" expand="sm" className="navbar">
            <Container>
                <Navbar.Brand id="mobile-navbar-brand"><Logo src={logo}/></Navbar.Brand> 
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        {generateMenu(leftMenuItems)}
                        <Nav.Link id="desktop-navbar-brand"><Logo src={logo}/></Nav.Link> 
                        {generateMenu(rightMenuItems)} 
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

const mapStateToProps = (state) => {
    return {
        section: state.navbarReducer
    }
}

export default connect(mapStateToProps, { chooseSection })(NavbarComponent);