import { GET_ALL_PORTFOLIO } from "../actions/types";
const initialState = []

function portfolioReducer(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_ALL_PORTFOLIO:
            return payload
        default:
            return state
    }
}

export default portfolioReducer