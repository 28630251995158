import shoppingService  from "../services/shopping.service"
import { GET_ALL_PRODUCT, GET_ALL_PRODUCT_BY_CATEGORY } from "../redux/actions/types"

const getAllProductsByCategory = (id) => async (dispatch) => {
    try {
        const res = await shoppingService.fetchAllProductByCategory(id)
        dispatch({
            type: GET_ALL_PRODUCT_BY_CATEGORY,
            payload: res.data.result
        })
    } catch (err) {
        return Promise.reject(err)
    }
}

const getProductById = (id) => async () =>{
    try{
        const res = await shoppingService.fetchById(id)
        return Promise.resolve(res)
    }catch(err){
        return Promise.reject(err)
    }
}

const getAllProduct = () => async (dispatch) =>{
    try{
        const res = await shoppingService.fetchAllProduct()
        dispatch({
            type : GET_ALL_PRODUCT,
            payload : res.data.result
        })
    }catch(err){
        return Promise.reject(err)
    }
}


const deleteProduct = (id) => async()=>{
    try{
        const res = await shoppingService.deleteProduct(id)
        return Promise.resolve(res)
    }catch(err){
        return Promise.reject(err)
    }
}

const submitProduct = (data) => async () =>{
    try{
        let deletedAssetsParam = data.deletedAssets
        if(deletedAssetsParam !== undefined){
            deletedAssetsParam = deletedAssetsParam.map((data)=>(data)).join(";")
        } else {
            deletedAssetsParam = ""
        }
        
        data.deletedAssetsParam = deletedAssetsParam

        const res = await shoppingService.submitProduct(data)
        return Promise.resolve(res)

    }catch(err){
        return Promise.reject(err)
    }
}

export {
    getAllProductsByCategory,
    getAllProduct,
    deleteProduct,
    submitProduct,
    getProductById
}