import React from "react";
import { Paragraph, Flex, Span } from "../../utils/styles.util";

function Advantage(props){
    return(
        <Flex justifyStart>
            <img src={props.image} alt="advantage icon" width="80" height="80"/>
            <Flex column alignBaseline>
                <Span bold medium>{props.title}</Span>
                <Paragraph small fontPoppinsLight>{props.desc}</Paragraph>
            </Flex>
        </Flex>
    )
}

export default Advantage