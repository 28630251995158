import categoryProductService from "../services/categoryproduct.service" 
import { GET_ALL_CATEGORY_PRODUCT } from "../redux/actions/types"

const getAllCategoryProduct = () => async (dispatch) => {
    try {
        const res = await categoryProductService.fetchAllCategoryProduct()
        dispatch({
            type: GET_ALL_CATEGORY_PRODUCT,
            payload: res.data.result
        })
    } catch (err) {
        return Promise.reject(err)
    }
}

export {
    getAllCategoryProduct
}