import React, { useState } from "react";
import { Container, Row } from 'react-bootstrap';
import styled from "styled-components";
import { COLORS } from "../../utils/constant.util";
import { Flex, Span } from '../../utils/styles.util';

/**
 * Usage :
 * Provide data for tab component with these structure
 * titles: array of string for the tab titles
 * content: array of components as the content for each title, you can pass anything you want to render here
 * example:
 *  let data = {
        "title": ["Foto","Video"], 
        "content": [ 
           <ContentGrid content={imageComponents}/>,
           <ContentGrid content={videoComponents}/>
        ]
    }
    <TabComponent {...data}/>
    **Please be assure of the data order you provide to the tab component, 
    ORDER SENSITIVE**
 */
const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const Line = styled.span`
    height: 0.2rem;
    width: 100%;
    background-color: ${COLORS.primary};
`

const TabComponent = (props) => {
    const [activeTab, setActive] = useState(0);
    
    return(
        <Container>
            <Row style={{"justifyContent": "space-around"}}>
              <Flex justifyCenter wrap="true">
                    {
                      props.titles.map((title, index)=>
                        <Wrapper 
                          key={index} 
                          onClick={()=>setActive(index)} 
                          style={{"marginLeft": "2rem", "marginRight": "2rem"}}>
                          <Flex column alignCenter>
                              <Span alignCenter medium style={{"color": `${index === activeTab ? COLORS.primary : COLORS.grey}`}}>
                                {title}
                              </Span>
                              {index === activeTab ? <Line/> : <></>}
                          </Flex>
                        </Wrapper>
                      )
                    }
              </Flex>
            </Row>
            <Row className="justify-content-md-center" style={{"marginTop": "3rem", "justifyContent": "center"}}>
                {props.content[activeTab]}
            </Row>
        </Container>    
    )
}

export default TabComponent;
