import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Flex, Paragraph, Centered } from "../../utils/styles.util";
import Heading from "../shared/heading.component";
import background from '../../assets/bg.jpeg';

const Canvas = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
`

const NotFound = (props) => {
    return(
        <Canvas>
            <Centered>
            <Flex justifyCenter column alignCenter>
                <Heading big title="Kelihatannya kamu tersesat" alignCenter/>
                <Paragraph alignCenter medium>Ayo balik sebelum gelap!</Paragraph>
                <Button 
                    onClick={() => props.history.replace("/")}
                    style={{
                        "borderRadius": "8",
                        "fontWeight": "bold",
                        "height": "3rem",
                        "paddingLeft": "2rem",
                        "paddingRight": "2rem",
                    }}>
                        Balik ke Rumah
                </Button>
            </Flex>
            </Centered>
        </Canvas>
    )
}

export default NotFound