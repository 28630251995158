import { GET_PORTOFOLIO_BY_ID } from "../actions/types";
const initialState = {}

function portofolioDetailReducer(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_PORTOFOLIO_BY_ID:
            return payload
        default:
            return state
    }
}

export default portofolioDetailReducer