import portfolioService from "../services/portfolio.service"
import { GET_ALL_PORTFOLIO } from "../redux/actions/types"

const getAllPortfolio = () => async (dispatch) => {
    try {
        const res = await portfolioService.fetchPortofolioList()
        dispatch({
            type: GET_ALL_PORTFOLIO,
            payload: res.data.result
        })
    } catch (err) {
        return Promise.reject(err) 
    }
}

const getPortofolioData = (id) => async () => {
    try {
        const res = await portfolioService.fetchById(id)
        return Promise.resolve(res.data.result[0])
    } catch (err) {
        return Promise.reject(err)
    }
} 

const submitPortofolio = (data) => async () => {
    try {
        let newVideoURLs = data.videoURLs
            .filter((video) => video.id === -1)

        if(newVideoURLs !== undefined){
            newVideoURLs = newVideoURLs.map((video) => (video.url)).join(";")
        } else {
            newVideoURLs = ""
        }
           
        let updatedVideoURLs = data.videoURLs
        .filter((video) => video.id !== -1)

        if(updatedVideoURLs !== undefined){
            updatedVideoURLs = updatedVideoURLs.map((video) => {
                return `${video.id}-${video.url}`
            })
            .join(";")
        } else {
            updatedVideoURLs = ""
        }
        let deletedAssetsParam = data.deletedAssets
        if(deletedAssetsParam !== undefined){
            deletedAssetsParam = deletedAssetsParam.map((data)=>(data)).join(";")
        } else {
            deletedAssetsParam = ""
        }
    
        data.videoURLParam = newVideoURLs
        data.updatedVideoURLs = updatedVideoURLs
        data.deletedAssetsParam = deletedAssetsParam

        const res = await portfolioService.submitPortofolio(data)
        return Promise.resolve(res)
    } catch (err) {
        return Promise.reject(err)
    }
}

const deletePortofolio = (id)=> async()=>{
    try{
        const res = await portfolioService.deletePortofolio(id)
        return Promise.resolve(res)
    }catch(err){
        return Promise.reject(err)
    }
}

export {
    getAllPortfolio,
    submitPortofolio,
    getPortofolioData,
    deletePortofolio
}