import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../utils/styles.util";
import { Link } from "react-router-dom";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 16rem);
    grid-auto-rows: 16rem;
    max-width: 60vw;

    @media (max-width: 678px){
        display: flex;
        flex-direction: column;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; 
`

const Wrapper = styled.div`
    position: relative;
    text-align: center;
    color: white;
`

const CategoryText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
function CategoryBox(props){
    return(
        <Grid>
        {props.content.map((prop,i)=>
            <Wrapper key={i}>
                <Link  to={{"pathname": "/shopping/"+prop.id}} style={{ textDecoration: 'none' }}>
                <Image src={prop.ImageURL}/>
                <CategoryText>
                    <Paragraph fontFuturaCondensedMedium medium bold white>{prop.name}</Paragraph>
                </CategoryText>
                </Link>
            </Wrapper> 
        
        )}
        </Grid>
    )
}

export default CategoryBox