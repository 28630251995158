import { NAVBAR_CHOOSE_SECTION } from "../actions/types";

function loginReducer(state = {}, action){
    const { type, payload } = action;

    switch(type){
        case NAVBAR_CHOOSE_SECTION:
            return payload
        default:
            return state
    }
}

export default loginReducer