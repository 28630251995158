import React from "react";
import { Carousel } from "react-bootstrap";
import styled  from "styled-components";
import { Paragraph,Centered } from "../../utils/styles.util";
import '../../styles/carousel.css'
import blocks from '../../assets/blocks.png';
import cascade1 from '../../assets/cascade01.png';
import cascade2 from '../../assets/cascade02.png';
import cascade3 from '../../assets/cascade03.png';
import cascade4 from '../../assets/cascade04.png';
import cascade5 from '../../assets/cascade05.png';

const BackgroundImage = styled.img`
    object-fit: cover;
    width:100vw;
    height: 110vh;
    display:block;
    filter: brightness(70%); 
`

const Container = styled.div`
    height: 110vh;
    position: relative !important;
`

const CarouselWrapper = styled.div`
    position: absolute;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%);
    z-index: 2;
    height: 110vh;
`

const Blocks = styled.img`
    width: 100vw;
    z-index: 1;
    bottom:0;
    right:0;
    position: absolute;
    height: 25%;
    object-fit: cover;
    object-position: 100% 100%;
`

export default function CarouselComponent(){
    const items = [
        {
            src: cascade1,
            title: "Cascade Interior",
            subtitle: "Bangun baru, renovasi, interior design. Home, Office, Retail. Based in Jakarta."
        },
        {
            src: cascade2,
            title: "Design with passion, mengerti kebutuhan Anda",
            subtitle: "Pelayanan yang ramah dan cepat tanggap. Hasil sesuai dengan design 3D. Bergaransi"
        },
        {
            src: cascade3,
            title: "Tim design dan tim pengawas yang bertanggung jawab",
            subtitle: "Menggunakan bahan material berkualitas. Terpercaya dan sesuai timeline"
        },
        {
            src: cascade4,
            title: "We create a functional and beautiful design",
            subtitle: "We build your dream house/office/retail"
        },
        {
            src: cascade5,
            title: "Segera hubungi kami dan konsultasikan kebutuhanmu",
            subtitle: ""
        }
    ]

    return(
        <Container>
            <CarouselWrapper>
                <Carousel nextLabel="" prevLabel="" interval={5000} fade>
                    {
                        items.map((item) => (
                            <Carousel.Item key={item.title}>
                                <BackgroundImage
                                    src={item.src}
                                    alt={item.title}/>
                                <Centered style={{"textAlign":"center"}}>
                                    <Paragraph big bold white>{item.title}</Paragraph>
                                    <Paragraph medium white>{item.subtitle}</Paragraph>
                                </Centered>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
             </CarouselWrapper>
             <Blocks src={blocks} alt="blocks"/>
         </Container>
    )
}