import React from "react";
import styled from "styled-components";
import { Span } from "../../utils/styles.util";
import { Link } from "react-router-dom";
const Image = styled.img`
    width: 100%;
    height: 14rem;
    object-fit: cover;    
    border-radius: 6px;
`

function PortofolioBox(props){
    return(
        <>
            <Link to={{"pathname": "/portfolio/"+props.id}} style={{ textDecoration: 'none', display: "block" }}> 
                <Image src={props.mainImage}/>
                <Span style={{
                    overflow:"hidden",
                    display:"-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    lineHeight: "1.2em",
                    marginTop: "1rem",
                    textAlign: "center"
                    }} fontFuturaCondensedMedium big>{props.title}</Span>
            </Link>
        </>
    )
}

export default PortofolioBox