import React from "react";
import styled from "styled-components";
import { Span } from "../../utils/styles.util";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 16rem);
    grid-gap: 2rem;
    max-width: 80vw;

    @media (max-width: 678px){
        display: flex;
        flex-direction: column;
    }
`


const ContentGrid = (props) => {
    if(props.content.length === 0){
        return (
            <Span big bold>NO DATA</Span>
        )
    }
    else{
        return(
            <Grid>
                {props.content.map((prop,i)=><div key={i}>{prop}</div>)}
            </Grid>    
        )
    }
   
}

export default ContentGrid;
